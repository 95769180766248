@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}


/* Main container */
.main-container {
  @apply w-[1512px] h-[982px] bg-[#fff] relative overflow-hidden mx-auto my-0;
}

.header {
  @apply flex items-center justify-between w-full h-auto py-4 px-8 bg-white;
}

.logo-text {
  @apply h-[40px] shrink-0 basis-auto font-['Inter'] text-[36px] font-bold leading-[40px] text-[#1e3a8a] relative text-left whitespace-nowrap z-[269];
}

.logo-image {
  @apply w-8 h-8 object-contain;
}

.header-buttons {
  @apply flex justify-between items-center gap-4;
}

.button-group {
  @apply flex gap-4;
}

.market-btn, .portfolio-btn, .transactions-btn, .perpetuals-btn, .testing-btn {
  @apply flex items-center gap-2 bg-[#f9fafb] rounded-[24px] px-6 py-2 border border-[#93c5fd] cursor-pointer;
}

.market-text, .portfolio-text, .transactions-text, .perpetuals-text, .testing-text {
  @apply text-blue-600 font-bold text-lg no-underline hover:underline;
}


.market-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative z-[273];
  background-image: url('../public/images/market-icon.png');
}

.balance-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/balance-icon.png');
}

.tooltip-icon {
  @apply w-[12px] h-[12px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/tooltip-icon.png');
}

.copy-icon {
  @apply w-[12px] h-[12px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/copy-icon.png');
}

.portfolio-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/portfolio-icon.png');
}

.deposit-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/deposit-icon.png');
}

.trade-button-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/trade-button-icon.png');
}
.long-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/long-icon.png');
}
.short-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/short-icon.png');
}

.transaction-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/transaction-icon.png');
}

.back-icon {
  @apply w-[20px] h-[17.778px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/back-icon.png');
}

.profit-icon {
  @apply w-[10px] h-[10px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/profit-icon.png');
}
.loss-icon {
  @apply w-[10px] h-[10px] shrink-0 bg-cover bg-no-repeat relative;
  background-image: url('../public/images/loss-icon.png');
}

.funding-info {
  @apply flex w-[262px] gap-[8px] items-center shrink-0 flex-nowrap relative z-[279];
}

.funding-info {
  @apply flex items-center gap-2;
}

.funding-icon {
  @apply w-6 h-6 bg-cover bg-no-repeat;
}

.funding-text {
  @apply flex items-center gap-2;
}

.funding-time {
  @apply text-lg font-semibold;
}

.time-left {
  @apply text-blue-600;
}

.total-time {
  @apply text-gray-500;
}

.funding-interval {
  @apply text-sm text-gray-500;
}

.account-btn {
  @apply flex items-center justify-between gap-2 bg-[#f9fafb] rounded-[24px] px-6 py-2 border border-[#93c5fd] cursor-pointer;
}

.account-icon .account-icon-end{
  @apply w-6 h-6 bg-cover bg-no-repeat;
}

.account-text {
  @apply text-blue-600 font-bold text-lg no-underline;
}

.account-icon-end {
  @apply w-6 h-6 bg-cover bg-no-repeat;
}

.account-info, .balance-info {
  @apply flex justify-between items-center gap-4;
}

.account-section {
  @apply flex items-center justify-end;
}

